import * as React from "react";

class ExternalLink extends React.Component {
  render() {
    return (
      <a href={this.props.url} target="_blank" rel="noreferrer noopener">
        {this.props.content}
      </a>
    );
  }
}

export default ExternalLink;
