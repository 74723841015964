import React from "react";
import "./style.css";

class Icon extends React.Component {
  render() {
    return (
      <a href={this.props.url} target="_blank" rel="noopener noreferrer">
        <div className="social-media-icon" style={{marginRight: this.props.marginRight}}>
          <img src={this.props.imgSrc} alt="icon"></img>
        </div>
      </a>
    );
  }
}

export default Icon;