import * as React from "react";

import Layout from "../../components/layout";
import ExternalLink from "../../components/external-link";
import Spacer from "../../components/spacer";
import "./style.css";

import GracImg from "./img/grac.png";

const IndexPage = () => {
  return (
    <Layout title="about | 🧃" bgColor="#e7ede6">
      <div id="about-page">
        <div id="about-text">
          <h1>hi, welcome to grac's bytes 👋</h1>
          <p>
            <strong>what are bytes?</strong> bytes are smol "byte-sized" projects i'm
            tinkering with :) also encoders of information in computers
          </p>
          <p>
            <strong>who is grac?</strong> hi, i'm grace (also known as grac)! i'm a 
            student at penn that likes making things. i wanted to start this site 
            as a collection of all my tinkerings ohoho
            <br/><br/>
            <ExternalLink url="https://gracejiang.me" content="more about me &rarr;"/>
          </p>
        </div>
        <Spacer width="5%" />
        <div id="about-img">
          <img src={GracImg} alt="pic of grac" />
          <p><em>it me</em></p>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
