import * as React from "react";

import "./style.css"

class Spacer extends React.Component {
  render() {
    return (
      <div style={{width: this.props.width, display: "inline-block"}}/>
    );
  }
}

export default Spacer;
