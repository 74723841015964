import * as React from "react";

import "./style.css";

export default function Navbar() {
  return (
    <div>
      <ul className="navbar-custom">
      <a href="/"><li className="navbar-left">Home</li></a>
      <a href="/about"><li className="navbar-left">About</li></a>

      <a href="/music"><li className="navbar-right">Music</li></a>
      <a href="/misc"><li className="navbar-right">Misc</li></a>

      {/* <a href="/projects"><li className="navbar-right">Projects</li></a> */}
      {/* <a href="/about"><li className="navbar-right">About</li></a> */}
      </ul>
    </div>
  );
}
