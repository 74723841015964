import * as React from "react";

import "./style.css";
import "../../styles/global.css";

import FavIcon from "../../img/favicon.png";

import { Helmet } from "react-helmet";

import CenterContainer from "../center-container";
import Navbar from "../navbar";
import Footer from "../footer";

class Layout extends React.Component {
  render() {
    return (
      <html lang="en">
        <head>
          <Helmet>
            <title>{this.props.title}</title>
            <link rel="icon" href={FavIcon} />
          </Helmet>
        </head>
        <body style={{ backgroundColor: this.props.bgColor }}>
          <div style={{ display: "none" }}>
            <img
              src="https://hitwebcounter.com/counter/counter.php?page=7934349&style=0007&nbdigits=6&type=ip&initCount=0"
              title="Free Counter"
              Alt="web counter"
              border="0"
            />
          </div>
          <CenterContainer>
            <Navbar />
            {this.props.children}
            <Footer />
          </CenterContainer>
        </body>
      </html>
    );
  }
}

export default Layout;
