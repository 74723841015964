import * as React from "react";

import "./style.css";
import "../../styles/global.css";

import Icon from "../icon";
import SpotifyIcon from "../../img/icons/spotify.svg";
import GitHubIcon from "../../img/icons/github.svg";

import ExternalLink from "../external-link";

export default function Footer() {
  return (
    <footer>
        <p>made with &#9829; by <ExternalLink url="http://gracejiang.me/" content={[<span style={{color: "#999", fontWeight: "600"}}>grac</span>]} /></p>
  
      <Icon
        imgSrc={SpotifyIcon}
        url="https://open.spotify.com/user/gjiang00"
        marginRight="8px"
      />
     
      <Icon
        imgSrc={GitHubIcon}
        url="https://github.com/gracejiang"
      />
    </footer>
  );
}
